<template>
  <div class="d-inline">
    <v-autocomplete
      :items="orderBy(company_profiles, 'name', 'asc')"
      class="dashboard-select field-shadow"
      placeholder="Search company profiles"
      :loading="status.gettingAllProfiles"
      @change="$emit('updated', $event)"
      v-model="search.companyProfile"
      item-text="name"
      item-value="id"
      hide-details
      outlined
      dense
    ></v-autocomplete>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      orderBy
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      search: state => state.request.search,
      status: state => state.brand_profile.status,
      company_profiles: state => state.brand_profile.company_profiles,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'getAllProfiles'
    ]),
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoadAllProfiles) {
      this.getAllProfiles()
    }
  }
}
</script>