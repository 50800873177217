<template>
  <div>
    <Search 
      v-if="user && (user.organization || user.role === 'admin')"
      :requests="requests" 
      @selected="startSearch" 
    />

    <preloader v-if="status.getting && !requests.length" message="Please wait..." />
    
    <v-alert type="info" v-if="!status.getting && !requests.length" border="left" text>
      No request found.
    </v-alert>

    <div v-if="requests.length">
      <v-row class="mb-2 text-uppercase font-weight-bold grey--text caption" no-gutters align="center">
        <v-col cols="1" style="max-width: 80px !important;">
          &nbsp;
        </v-col>
        <v-col class="pl-5" cols="3">
          Client
        </v-col>
        <v-col class="pl-5" cols="2">
          Type
        </v-col>
        <v-col class="pl-5" cols="2">
          Deadline
        </v-col>
        <v-col class="pl-5" cols="2">
          Assignee
        </v-col>
        <v-col class="pl-5" cols="1">
          Status
        </v-col>
      </v-row>

      <v-hover
        v-for="request in orderBy(requests, 'orderNumber', 'asc')"
        v-slot="{ hover }"
        :key="request.id"
      >
        <v-card 
          class="d-flex rounded-lg mb-2 cursor-default transition"
          :to="{ name: 'Request', params: { id: request.id } }"
          :class="{ 'box-shadow' : hover }"
          outlined
        >
          <v-row no-gutters align="center">
            <v-col cols="1" 
              class="fill-height d-flex align-center justify-center request_priority"
              :class="
                user.role == 'client' ? `${request.status == 'completed' ? 'request_priority__medium' : 'request_priority__none'}` :  `request_priority__${request.priority || 'none'}`
              "
            >
              <v-card-text class="text-center py-0"> 
                <v-avatar
                  size="24"
                  tile
                >
                  <img v-if="['in_progress', 'checking_quality', 'reviewing_with_client', 'paused', 'pending_review'].includes(request.status)" :src="require('@/assets/badge-icon-open.svg')" alt="open">
                  <img v-if="request.status == 'completed'" :src="require('@/assets/badge-icon-completed.svg')" alt="delivered">
                  <template v-if="request.status == 'escalated'">
                    <v-icon v-if="request.status == 'escalated' && user && user.role !== 'client'" color="error">mdi-alert-circle-outline</v-icon>
                    <img v-else :src="require('@/assets/badge-icon-open.svg')" alt="open">
                  </template>
                </v-avatar>
              </v-card-text>
            </v-col>
            <!-- REQUEST CLIENT -->
            <v-col cols="3">
              <v-card-text class="py-0">
                <v-card-text class="d-flex align-center px-0">
                  <v-badge 
                    :value="haveMessage(request)"
                    color="transparent" 
                    size="15"
                    overlap
                    bottom 
                    left 
                  >
                    <template #badge>
                      <span class="wave" :style="{ fontSize: '20px' }">👋</span>
                    </template>
                    
                    <user-photo
                      class="mr-3 align-self-start"
                      :id="request.user"
                      photoSize="thumb"
                      :size="30"
                      rounded
                    />
                  </v-badge>

                  <div>
                    <div class="caption">
                      <span class="blue-grey--text">{{ $store.getters['users/getFullName'](request.user) }}</span>
                      <span class="grey--text ml-1">({{ request.created | fromNowComplete }})</span>
                    </div>
                  </div>
                </v-card-text>
              </v-card-text>
            </v-col>
            <!-- REQUEST TYPE -->
            <v-col cols="2">
              <v-card-text>
                <div class="caption blue-grey--text">{{ $store.getters['types/getProductName'](request.requestType) }}</div>
                <div class="info--text caption" :style="{ lineHeight: '1 !important' }">#{{ request.requestNumber }}</div>
              </v-card-text>
            </v-col>
            <!-- DUE DATE -->
            <v-col cols="2">
              <v-card-text class="caption ">
                <span class="blue-grey--text">
                  {{ request.dueDate ? `${$options.filters.formatDate(request.dueDate)}` : 'TBA' }}
                </span>
                <span v-if="request.dueDate && moment(request.dueDate.toDate()).diff(moment(), 'hours') > -24 && moment(request.dueDate.toDate()).diff(moment(), 'hours') < 0" class="info--text">
                  today
                </span>
                <span v-else-if="request.dueDate" class="ml-1" :class="$overdue(request) ? 'error--text' : 'grey--text lighten-2' ">
                  {{ moment(request.dueDate.toDate()).fromNow() }}
                </span>
              </v-card-text>
            </v-col>
            <!-- ASSIGNEE -->
            <v-col cols="2">
              <v-card-text class="d-flex align-center">
                <Assignee :request="request" small />
              </v-card-text>
            </v-col>
            <!-- STATUS -->
            <v-col cols="1">
              <v-card-text>
                <div class="d-flex align-center full-width">
                  <StatusBadge :request="request" />
                </div>
              </v-card-text>
            </v-col>

            <v-col class="text-right pr-3">
              <v-menu offset-y left>
                <template #activator="{ on }">
                  <v-btn @click.prevent v-on="on" icon>
                    <v-icon color="grey" small>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item :to="{ name: 'Request', params: { id: request.id } }">
                    <v-list-item-title>View</v-list-item-title>
                  </v-list-item>
                  <template v-if="$store.getters['request/canManage']">
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-title class="grey--text caption">Update Priority</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateRequestPriority(request, 'high')">
                      <v-list-item-title>High</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateRequestPriority(request, 'medium')">
                      <v-list-item-title>Medium</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateRequestPriority(request, 'low')">
                      <v-list-item-title>Low</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="attemptDelete(request)">
                      <v-list-item-title class="error--text">Delete</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <div class="text-center mt-5">
        <v-btn
          @click="$store.dispatch('request/getRequests', type)"
          :loading="$store.state.request.status.getting"
          color="primary" 
          small
        >Load More</v-btn>
      </div>

      <!-- CONFIRM DELETE -->
      <confirm-delete
        @confirmed="deleteConfirmed()"
        @cancel="closeDeleteDialog()"
        :message="`Delete request #${toDelete.requestNumber}?`"
        :deleting="$store.state.request.status.deleting"
        textConfirmation="Request"
        :show="deleteDialog"
        captcha
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

import Search from './Search'
import Assignee from './Assignee'
import StatusBadge from './StatusBadge'

export default {
  props: {
    requests: Array,
    type: String,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Search,
    Assignee,
    StatusBadge,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      moment,
      orderBy,
      toDelete: {},
      deleteDialog: false,
      resentMessages: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.request.status,
      requestResponseTime: state => state.generalSettings.settings.requestResponseTime
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'requests': {
      handler(requests) {
        if (requests && requests.length) {
          requests.forEach(request => {
            this.getRequestMessage(request)
          })
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('types', [
      'getProduct',
      'getType',
    ]),
    
    ...mapActions('request', [
      'updateRequestField',
      'deleteRequest',
      'getRequests',
    ]),

    checkRequests() {
      if (this.requests) {
        this.requests.forEach(request => {
          this.getType(request.requestCategory)
          this.getProduct({
            type: request.requestCategory,
            product: request.requestType
          })
        })
      }
    },

    attemptDelete(request) {
      this.toDelete = request
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.deleteDialog = false
      this.toDelete = {}
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteRequest(this.toDelete))
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    startSearch(value) {
      if (value) {
        var type = this.$route.name == 'CompletedRequests' ? 'completed' : (this.$route.name == 'OpenRequests' ? 'open' : 'pending')
        this.$store.commit('request/resetRequests')
        this.$store.commit('request/updateStatus', { search: value })
        this.getRequests(type)
      }
    },

    getRequestMessage(request) {
      if (!this.haveMessage(request) && request.status !== 'completed') {
        request.ref
        .collection('messages')
        .orderBy('created', 'desc')
        .limit(1).get()
        .then((snapshot) => {
          if (snapshot.size) {
            let doc = snapshot.docs[0]
            let created = moment().diff(doc.data().created.toDate(), 'hours')
            
            if (
              request.user == doc.data().sender && 
              doc.data().type !== 'status_change' &&
              created >= this.requestResponseTime &&
              !doc.data().note
            ) {
              this.resentMessages.push(doc)
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        })
      }
    },

    haveMessage(request) {
      return !!this.resentMessages.find(m => {
        return m.ref.parent.parent.id == request.id && request.user !== this.user.userid
      })
    },

    updateRequestPriority(request, priority) {
      this.updateRequestField({
        request,
        field: 'priority',
        value: priority
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.checkRequests()
  }
}
</script>

<style lang="scss">
.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

.request_priority {
  max-width: 80px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
  border-right: 1px solid #e9ebf4; 
  box-shadow: -4px 0 0 var(--v-dark-lighten5);

  &__high {
    box-shadow: -4px 0 0 var(--v-error-darken1);
  }
  
  &__medium {
    box-shadow: -4px 0 0 var(--v-success-darken1);
  }
  
  &__low {
    box-shadow: -4px 0 0 var(--v-dark-lighten5);
  }
}
</style>